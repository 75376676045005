/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import MakonariLogo from "../images/logo_makonari.png"
import AllbirdsLogo from "../images/logo_allbirds.png"

import Header from "./header"
import {Helmet} from "react-helmet";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link href="//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className="mt-5 sm:mt-20 overflow-hidden">{children}</main>
      <footer className="p-5 mt-10 bg-bgdark">
        <ul className="flex justify-center items-center">
          <li className="px-10 py-2 border-r-2 border-white">
            <img src={MakonariLogo} alt="Makonari" className="w-20 block" />
          </li>
          <li className="p-10">
            <img src={AllbirdsLogo} alt="Allbirds" className="w-32 block" />
          </li>
        </ul>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
