import PropTypes from "prop-types"
import React from "react"
import HeaderStyle from "../css/header.module.scss"
import MakonariLogo from "../images/logo_makonari.png"
import AllbirdsLogo from "../images/logo_allbirds.png"
import TitleMakonariAllbirds from "../images/title_makonari-allbirds.png"
import LogoCampaign from "../images/logo_campaign.png"
import Shoes01 from "../images/shoes_01.png"
import Shoes02 from "../images/shoes_02.png"

const Header = ({ siteTitle }) => (
  <header className={`${HeaderStyle.header} bg-bgdark font-bold`}>
    <ul className="flex justify-center items-center pt-5">
      <li className="px-10 py-1 border-r-2 border-white">
        <img src={MakonariLogo} alt="Makonari" className="w-16 block" />
      </li>
      <li className="px-10 py-5">
        <img src={AllbirdsLogo} alt="Allbirds" className="w-24 block" />
      </li>
    </ul>
    <div className="relative">
      <div className="relative z-10">
        <img src={TitleMakonariAllbirds} alt="マコなり社長×allbirds" className="w-3/4 sm:w-3/12 block mx-auto" />
        <img src={LogoCampaign} alt="感謝の手紙キャンペーン" className="w-3/4 sm:w-4/12 block mx-auto -mt-2" />
      </div>
    </div>
    <p className={`${HeaderStyle.headerText} w-4/5 mx-auto text-center text-lg mt-5 text-white`}>
      大切な人に「感謝の手紙」を書く＆
      <br />
      「#マコなり社長オールバーズ感謝の手紙」をつけてツイート
      <strong className="table mx-auto text-2xl mt-5">感謝を伝えてAllbirdsの靴を手に入れよう</strong>
    </p>

    <div className="bg-white bg-opacity-75 pt-2 mt-10">
      <div className="relative text-center w-11/12 sm:w-1/2 mx-auto bg-sub mt-10 p-4">
        <h3 className="table mx-auto py-2 px-5 border-sub border-2 bg-bgdark -m-8">応募期間</h3>
        <p className="mt-10">
          2020年11月20日（金）20:00　〜　2020年11月29日（日）23:59
        </p>
      </div>

      <div className={`${HeaderStyle.shoes} relative text-center w-11/12 sm:w-1/2 mx-auto bg-sub mt-10 p-4`}>
        <h3 className="table mx-auto py-2 px-5 border-sub border-2 bg-bgdark -m-8">プレゼント賞品</h3>
        <p className="mt-10">
          Allbirds原宿店・オンラインサイトで
          <br />
          使用できるギフトカード（12,500円分）を
          <br />
          抽選で1,000名様にプレゼント！
        </p>
        <img src={Shoes01} className={`${HeaderStyle.shoes01} block absolute`} />
        <img src={Shoes02} className={`${HeaderStyle.shoes02} block absolute`} />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
